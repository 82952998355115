.leftBox {
    border-radius: 20px;
    margin-top: 20px;
    padding: 20px
}
.rightBox {
    border-radius: 20px;
    margin-top: 20px;
    padding: 20px
}
.header {
    font-weight: bold !important;
    color: #5E8A75
}
.button {
    box-shadow: 3
}
.buttonGrid {
    text-align: center !important;
    margin-top: 100px !important;
}

@media screen and (max-width: 900px) {
    .buttonGrid {
        margin-top: 0 !important;
    }
    .rightBox {
        margin-top: 0 !important;
    }
}