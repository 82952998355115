.detailsButton {
       background-color: green;
        border: none;
        color: white;
        font-size: small; 
}
.detailsButton:hover {
    background-color: #3f934e;
}
.statusSpan {
    background-color: #F8FFF6;
    color: #229A16;
    padding: 3px 6px;
    border-radius: 4px;
    display: inline-block;
}

.ag-theme-quartz-container {
    height: 400px;
}
