.schedule-container {
    padding: 20px;
    background-color: var(--snowwhite) !important;
    max-width: 1500px;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0 4px 12px var(--lightBlack);
}

.doctor-info-container {
    padding: 30px;
    margin-bottom: 30px;
    background-color: var(--white);
    border-radius: 8px;
    box-shadow: 0 4px 10px var(--lightBlack);
    width: 100%;
}

.schedule-section {
    width: 65%;
}

.day-schedule {
    margin-bottom: 20px;
    background-color: var(--white);
    border-radius: 8px;
    box-shadow: 0 2px 6px var(--lightBlack);
    padding: 20px;
    border: 1px solid var(--lightGrayBorder);
}

.day-schedule .MuiTypography-subtitle1 {
    margin-bottom: 20px;
    color: var(--darkGray);
    font-weight: 600;
}

.add-slot-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: var(--green) !important;
    color: var(--white) !important;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
}

.copy-previous-slot-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: var(--green) !important;
    color: var(--white) !important;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
}

.add-slot-button:hover {
    background-color: var(--darkGreen);
}

.save-button-container {
    display: flex;
    justify-content: center;
}

.save-button {
    background-color: var(--green) !important;
    color: var(--white) !important;
    margin: 16px auto;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

/* .save-button:hover {
    background-color: var(--darkerGreen) !important;
} */

.save-button:disabled {
    background-color: var(--lightGrayDisabled);
    cursor: not-allowed;
}

.MuiTextField-root {
    margin-bottom: 20px;
}

.MuiIconButton-root {
    padding: 10px;
    color: var(--dangerRed);
    transition: color 0.3s ease;
}

.MuiIconButton-root:hover {
    color: var(--darkDangerRed);
}

.MuiTypography-h6 {
    color: var(--darkText);
    font-weight: 700;
}

.MuiTypography-subtitle1 {
    color: var(--mediumGrayText);
}