.weight-loss-form {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-title {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.followup-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.form-section {
    background-color: #fff;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.form-label {
    display: inline;
    margin-bottom: 5px;
    font-weight: bold;
    color: #444;
}

.form-input {
    width: 100%;
    padding: 12px;
    border: 2px solid #e0e0e0;
    border-radius: 6px;
    font-size: 16px;
    transition: all 0.3s ease;
    background-color: #f8f9fa;
    color: #495057;
}

.form-input:focus {
    outline: none;
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    background-color: #fff;
}

.form-input::placeholder {
    color: #adb5bd;
}

.radio-group label {
    display: block;
    margin-bottom: 8px;
}

.radio-input {
    margin-right: 5px;
}

.checkbox-label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.checkbox-input {
    margin-right: 5px;
}

.submit-button {
    background-color: #007bff;
    color: #fff;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #0056b3;
}

.error-message {
    color: red;
    font-size: 0.9em;
    margin-top: 5px;
}

.success-message {
    color: #28a745;
    font-size: 14px;
    margin-top: 5px;
}

/* Responsive design */
@media (max-width: 600px) {
    .weight-loss-form {
        padding: 15px;
    }

    .form-input {
        font-size: 14px;
        padding: 10px;
    }

    .submit-button {
        font-size: 16px;
    }
}

.question-container {
    margin-bottom: 5px;
}

.input-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.input-field,
.textarea-field,
.select-field {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.textarea-field {
    resize: vertical;
    min-height: 100px;
}

.input-group {
    display: block;
    gap: 10px;
}

.height-weight-input {
    margin-bottom: 15px;
}

.section-title {
    font-size: 18px;
    margin-bottom: 10px;
}

.consent-text {
    margin-bottom: 10px;
    line-height: 1.5;
}

.bmi-display {
    background-color: #f0f0f0;
    padding: 15px;
    border-radius: 4px;
    margin-top: 20px;
}

.disqualified-text {
    color: #d32f2f;
    font-weight: bold;
}

.qualified-text {
    color: #388e3c;
    font-weight: bold;
}
