.formWrapper {
    width: 100%;
    margin: auto;
    /* padding: 1em 0; */
    display: flex;
    flex-direction: column;
    align-items: center;
}
.newPatientFormWrapper {
    width: 50%;
    margin: 3% auto;
    padding: 3em;
}

.container {
    position: relative;
    min-height: 100vh;
  }
  
  .previewButtonContainer {
    position: fixed;
    bottom: 20px;
    right: 20px;
  }

.patientFormWrapper {
    margin: auto;
    padding: 2em 0;
}

.formWrapperEditprofile {
    width: 80%;
    margin: 0 auto;
    padding: 1em 2em;
}

.heading {
    font-size: 30px;
}

.selectWrapper {
    width: 85%;
}

.searchWrapper {
    width: 85%;
    display: flex;
    align-items: center;
    gap: 1em;
}

.cardHeader {
    display: flex;
    align-items: center;
    gap: 1.2em;
}

@media (min-width: 280px) and (max-width: 1024px) {
    .formWrapper {
        width: 100%;
        padding: 2em 0;
    }

    .patientFormWrapper {
        width: 100%;
    }

    .heading {
        font-size: 32px;
    }

    .formWrapperEditprofile {
        width: 100%;
        padding: 1em 1em;
    }

    .selectWrapper {
        width: 100%;
    }

    .searchWrapper {
        width: 100%;
    }

    .cardHeader {
        display: flex;
        align-items: center;
        gap: 1.2em;
        flex-wrap: wrap;
    }
}