.box {
    margin-top: 2em;
}

.noMarginTop {
    margin-top: 0;
}

.marginBottom {
    margin-bottom: 2em;
}

.limitationsTitle {
    font-weight: bold;
}

.limitationsGrid {
    margin-left: 2em;
    margin-top: 1em;
}

.limitationsText {
    font-weight: bold;
}

.textBold {
    font-weight: bold;
}

.textContainer {
    margin-left: 2em;
}

.dentalBox {
    padding-left: 2em;
    padding-right: 2em;
    border-radius: 4px;
    border: 1px solid lightGray;
    margin-top: 2em;
    border-bottom: 0px !important;
}

.borderBottomZero {
    border-bottom: 0;
}

.paddingBottom1 {
    padding-bottom: 1rem;
}

.noMarginTop {
    margin-top: 0 !important;
}

