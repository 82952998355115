.formWrapper {
    background-color: #fff; /* White background */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    padding: 16px; /* Padding inside the box */
    margin: 16px; /* Margin around the box */
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.centeredBox {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fullWidthBox {
    width: 100%;
}

.marginTopBox {
    margin-top: 0.5em;
}

.textCenter {
    text-align: center;
}

.name-text {
    font-weight: bold;
    color: green;
}