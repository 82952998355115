.searchContainer {
    display: flex;
    align-items: center;
    gap: 1.2em;
}

.searchBox {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1em;
}

.formContainer {
    margin-top: 2em;
}

.formControl {
    margin-bottom: 2em;
    color: purple;
}
.capitalizeInput {
    text-transform: capitalize;
}

.textAlignRight {
    text-align: right;
}

.textAlignLeft {
    text-align: left;
}
.customBox {
    box-shadow: 0 0 2px 0 #919EAB;
    border-radius: 12px;
}

.flexBox {
    display: flex;
    align-items: center;
    gap: 1.2em;
}

.widthBox {
    width: 25%;
}

.marginTopBox {
    margin-top: 2em;
}
.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fullWidthBox {
    width: 100%;
}

.marginTopBox {
    margin-top: 0.5em;
}

.textAlignCenter {
    text-align: center;
}

.boldText {
    font-weight: bold;
    color: var(--themeGreen);
}

.marginTopTabs {
    margin-top: 5em;
}
