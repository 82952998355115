.autocompleteContainer {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1em;
  }
  
  .marginTopBox {
    margin-top: 2em;
  }
  
  .formControl {
    margin-bottom: 2em;
    color: purple;
  }

  .textAlignRight {
    text-align: right;
  }
  
  .textAlignLeft {
    text-align: left;
  }
  
  .buttonContainer {
    justify-content: center;
    margin: top 2px;
    padding-top: 1%;
    display: flex;
  }