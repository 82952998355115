/* PersonalInfo.css */

/* this css is being used in questionnaire follow up folder personal info file as well */

.personal-info {
    display: flex;
    flex-wrap: wrap;
    /* Allows items to wrap to the next line */
    gap: 16px;
    /* Space between fields */
}

.personal-info .form-group {
    flex: 1 1 calc(50% - 16px);
    /* Two fields per row with a small gap */
    min-width: 200px;
    /* Minimum width for each field */
}

.personal-info label {
    display: block;
    margin-bottom: 4px;
    /* Space between label and input */
}

.personal-info .required {
    color: red;
    /* Color for required asterisk */
}

.personal-info input {
    width: 100%;
    /* Full width of the parent container */
    padding: 8px;
    /* Padding inside input */
    border: 1px solid #ccc;
    /* Border for input */
    border-radius: 4px;
    /* Rounded corners */
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    /* Subtle inner shadow */
}