
/* this class is also being used for PersonalInfoForm.js file in questionnaire form */
.followUpInputs {
    width: 100%;
    padding: 12px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid var(--inputBorderGray);
    border-radius: 6px;
    transition: border-color 0.3s ease;
    background-color:   var(--disabledBg);
    color:var(--gray)
}

/* this class is also being used for PersonalInfoForm.js file in questionnaire form */
.followUpInputs:focus {
    outline: none;
}
