.headerContainer {
    display: flex;
    align-items: center;
    gap: 1.2em;
}

.patientName {
    width: 25%;
}

marginTop2em {
    margin-top: 2em;
}
