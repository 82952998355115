.myTabs {
    border-bottom: 1;
    background: var(--lightGrayishBlue);
    display: flex;
    justify-content: space-between;
    border-color: divider;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    margin-left: 0;
    width:100%
}

.tabInner {
    margin-left: 0em;
}

.mainContainer { 
    width: 100%
}

.tabsContainer {
    border-color: divider;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    margin-left: 0 
}

.activeTab {
    color: var(--black);
    margin-left: 0;
}
  
.inactiveTab {
    color: var(--mediumGray);
    margin-left: 0;
}

.tabButton {  
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
    margin-left: 0 !important;
    margin-right: 38px !important;
    padding-left: 2em !important;
    padding-right: 2em !important;
}

.dataBox {
    background: var(--white);
    box-shadow: 2px 2px 2px var(--surfaceLighter)
}

.viewTabMain {
    width: 100%
}

.viewTab {
    color: var(--themeBlue) !important;
    border-bottom: 2px solid var(--themeBlue) !important;
    margin-left: 0
}

@media (min-width: 280px) and (max-width: 1024px) {
    .myTabs {
        border-bottom: 1;
        background: var(--lightGrayishBlue);
        display: flex;
        flex-wrap: wrap;
    }

    .tabInner {
        margin-left: 0;
    }
}