/* this css is being used in questionnaire follow up folder personal info file as well */

.error-message1 {
    color: red;
    font-size: 0.9em;
    margin-top: 0.5em;
}

.checkbox1 {
    margin-right: 8px;
    /* Space between checkbox and label */
}

.consent-popup1 {
    position: fixed;
    /* Position it on the screen, not in the flow of the document */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* Center the popup */
    background-color: white;
    /* Set a white background for the popup */
    padding: 20px;
    /* Add padding around the content */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.);
    /* Add shadow for a raised effect */
    z-index: 1000;
    /* Make sure the popup is on top */
    width: 80%;
    /* Adjust width to fit the content */
    max-width: 600px;
    /* Limit the maximum width */
    border-radius: 10px;
    /* Optional: Rounded corners */
}

/* Style for the overlay to dim the background */
.modal-overlay1 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black background */
    z-index: 999;
    /* Ensure it's behind the popup but above other content */
}

/* Optional: Style for buttons inside the popup */
.consent-popup1 button {
    display: inline-block;
    margin: 10px;
    padding: 10px 15px;
    background-color: #007bff;
    /* Button color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.consent-popup1 button:hover {
    background-color: #0056b3;
    /* Darken on hover */
}


/* Container for the form */
.form-container1 {
    max-width: 800px;
    margin: 0 auto;
    background: white;
    padding: 15px 30px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}


.form1-h3 {
    margin-top: 25px;
    margin-bottom: 15px;
    color: #34495e;
    font-size: 1.5rem;
}

/* Styling for questions */
.question1 {
    margin-bottom: 5px;
}

/* Text area for free text responses */
.textarea1 {
    width: 100%;
    height: 120px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 6px;
    resize: none;
    transition: border-color 0.3s ease;
}

.textarea1:focus {
    border-color: #3498db;
    outline: none;
}

/* Input fields */


.forms-inputs-one {
    width: 100%;
    padding: 12px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 6px;
    transition: border-color 0.3s ease;
    
}


.forms-inputs-one:focus {
    border-color: #3498db;
    outline: none;
}

/* Checkbox and radio button styles */
.label-form {
    display: block;
    margin: 5px 0;
    font-size: 1rem;
    color: #333;
}

.form-checkbox-radio {
    margin-right: 10px;
}

/* Button styles */
.button1 {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 12px 20px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.button1:hover {
    transform: translateY(-2px);
}

/* Error messages */
.error1 {
    color: #e74c3c;
    font-size: 14px;
    margin-top: -10px;
    margin-bottom: 10px;
}


/* Media queries for responsive design */
@media (max-width: 600px) {
    .form-container1 {
        padding: 15px;
    }


    .form1-h3 {
        font-size: 1.25rem;
    }

    .button1 {
        width: 100%;
    }
}

.forms-inputs-select {
    width: 100%;
    padding: 10px 12px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid var(--inputBorderGray);
    border-radius: 6px;
    transition: border-color 0.3s ease;
}

.forms-inputs-select:focus {
    border-color: var(--selectedInputBorder); 
    outline: none;
}
