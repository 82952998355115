/* DentalSearch.module.css */

.formWrapper {
    /* You can add any general form styling here */
  }
  
  .textTransformCapitalize {
    text-transform: capitalize;
  }
  
  .searchContainer {
    display: flex;
    align-items: center;
    gap: 1.2em;
  }

    .capitalize-text {
      text-transform: capitalize;
    }
  
  .searchInnerContainer {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1em;
  }
  
  .firstNameField {
    margin-bottom: 2em;
    color: purple;
  }
  
  .lastNameField {
    margin-bottom: 2em;
  }
  
  .insuranceField {
    margin-bottom: 2em;
  }
  
  .dateOfServiceField {
    margin-bottom: 2em;
  }
  
  .npiField {
    margin-bottom: 2em;
  }
  
  .searchButtonContainer {
    padding-top: 140px;
  }
  
  .searchButton {
    margin-left: -0.7in;
  }
  
  .circularProgress {
    color: inherit;
    size: 22px;
  }
  