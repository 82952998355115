.formMargin {
    margin-bottom: 2em !important;
}

.optionIconButton {
    margin-bottom: 5px
}

.cursor {
    cursor: pointer
}

.editSection {
    box-shadow: 0 0 2px 0 var(--themeDarkGrayishBlue);
    border-radius: 12px
}

.borderStyle {
    width: 100%;
}

.borderStyleChild {
    border-color: divider;
    border-radius: 12px;
    margin-left: 0;
}

.button {
    margin-top: 0.5em;
    margin-left: 38px;
    margin-right: 38px;
    padding-left: 2em;
    padding-right: 2em;
}

.patientDetailTab {
    color: var(--themeBlue);
    border-bottom: 2px solid var(--themeBlue);
    margin-left: 0
}
.editPatient {
    display: flex;
    justify-content: flex-end;
    color: var(--green),
}
.patientFirstNameField {
     margin-bottom: 2em;
     color: var(--purple);
}
.mainDiv { 
    padding: 24px !important
}

