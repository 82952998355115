.cancelButton {
    background: var(--white) !important;
    color: var(--black) !important;
    border: 1px solid var(--darkBlue) !important;
    box-shadow: 0;
    font-size: 15px !important;
    padding: 8px 22px !important;
    &:hover {
        background-color: var(--surface) !important;
        color: var(--black);
    };
}

.notAllowedCursor {
    cursor: not-allowed !important;
}

.pointerCursor {
    cursor: pointer !important;
}

.saveButtonStyle {
    border-radius: 10px !important;
    background-color: var(--themeGreen) !important;
    font-size: 0.8125rem !important;
    padding: 4px 10px !important;
}