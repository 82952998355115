.containerXXL {
    padding: 1em 3em;
}

@media (max-width: 600px) {
    .containerXXL {
        padding: 0.6em 0.6em !important;
    }
}

@media (min-width: 280px) and (max-width: 1024px) {
    .containerXXL {
        padding: 1em 1em;
    }
}