

.searchWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1em;
    margin-bottom: 1em;
}

.cardHeader {
    display: flex;
    align-items: center;
    gap: 1.2em;
}

@media (min-width: 280px) and (max-width: 1024px) {
    .selectWrapper {
        width: 100%;
    }
    .searchWrapper {
        width: 100%;
    }

    .cardHeader {
        display: flex;
        align-items: center;
        gap: 1.2em;
        flex-wrap: wrap;
    }
}