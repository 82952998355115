.card-text {
    margin: 16px 0 !important; 
    font-size: 14px !important;
  }
  
  @media (min-width: 900px) and (max-width: 939px) {
    .card-text {
      font-size: 10px !important; 
    }
  }

  @media (min-width: 940px) and (max-width: 1112px) {
    .card-text {
      font-size: 12px !important; 
    }
  }

  
  @media (min-width: 1113px) {
    .card-text {
      font-size: 14px !important; 
    }
  }